import { redirectPageName } from "../utils/helpers";

export default async function auth({ next, store }) {
  if (!store.getters["auth/auth"].token) {
    return next({
      name: redirectPageName(),
    });
  }
  return next();
}
