import Vue from "vue";
import VueRouter from "vue-router";

// Import the layout files
import Dash from "@/components/Dash.vue";

// Import the middleware functions
import { auth, admin, guest, middlewarePipeline } from "../middleware";

// Import the store
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: { name: "Login" },
  },
  {
    path: "/login",
    name: "Login",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue"),
    meta: { title: "Login", middleware: [guest] },
  },
  {
    path: "/",
    component: Dash,
    children: [
      {
        path: "/admin",
        name: "AdminPanel",
        component: () =>
          import(/* webpackChunkName: "admin" */ "../views/AdminPanel.vue"),
        meta: { title: "Admin Panel", middleware: [auth, admin] },
      },
    ],
  },
  {
    path: "/404",
    name: "NotFound",
    component: () =>
      import(/* webpackChunkName: "not-found" */ "../views/NotFoundPage.vue"),
  },
  {
    path: "*",
    redirect: { name: "NotFound" },
  },
];

const router = new VueRouter({
  routes,
  // mode: 'history',
  // linkExactActiveClass: 'active',
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: "smooth",
      };
    }
    if (savedPosition) {
      return savedPosition;
    } else {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({ left: 0, top: 0 });
        }, 10);
      });
    }
  },
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || "Events App";

  if (!to.meta.middleware) {
    return next();
  }
  const middleware = to.meta.middleware;

  const context = {
    to,
    from,
    next,
    store,
  };

  return middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1),
  });
});

export default router;
