import store from "../../store";
const redirectPageName = () => {
  const isLoggedIn = store.getters["auth/loggedIn"];
  const role = store.state.auth.roleName;
  if (isLoggedIn && role === "Admin") {
    return "AdminPanel";
  }
  if (!isLoggedIn) {
    return "Login";
  }

  return "Login";
};

export { redirectPageName };
