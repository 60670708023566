import axios from "axios";
import store from "../store";
import router from "../router";

let isRefreshing = false;
let failedQueue = [];

const fetchToken = () => window.localStorage.getItem("token");

// Queue the requests that have been parallely called while refreshtoken is being called
// For the entire logic of JWT with interceptors, visit https://gist.github.com/Godofbrowser/bf118322301af3fc334437c683887c5f#file-axios-refresh_token-1-js
const processQueue = (error, token = null) => {
  failedQueue.forEach((promise) => {
    if (error) {
      promise.reject(error);
    } else {
      promise.resolve(token);
    }
  });

  failedQueue = [];
};

const fetchClient = () => {
  // Default config options
  const defaultOptions = {
    baseURL: `${process.env.VUE_APP_API_BASE_URL}/api/`,
  };

  // Create instance
  const instance = axios.create(defaultOptions);

  instance.interceptors.request.use(function (config) {
    if (config.url !== "/User/refreshtoken") {
      config.headers.Authorization = `Bearer ${fetchToken()}`;
    }
    return config;
  });

  instance.interceptors.response.use(undefined, async (err) => {
    const originalConfig = err.config;

    if (err.response.status === 401 && !originalConfig._retry) {
      if (isRefreshing) {
        // For the API requests to be sent to failedQueue, return a Promise request that would be later called by the processQueue function
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
          .then((updatedToken) => {
            originalConfig.headers["Authorization"] = "Bearer " + updatedToken;
            return axios(originalConfig);
          })
          .catch((error) => {
            return Promise.reject(error);
          });
      }

      originalConfig._retry = true;
      isRefreshing = true;

      // Handle errors only if its not Login API
      if (originalConfig.url !== "/Login" && err.response) {
        try {
          const rs = await instance.post("/User/refreshtoken", {
            refreshToken: store.getters["auth/auth"].refreshToken,
            Id: store.getters["auth/auth"].user.userId,
          });

          store.commit("auth/authSuccess", rs.data);
          processQueue(null, rs.data.data.token);

          return instance.request(originalConfig);
        } catch (_error) {
          processQueue(err, null);
          return Promise.reject(_error);
        } finally {
          isRefreshing = false;
        }
      } else {
        throw err;
      }
    } else if (
      err.response.status === 400 &&
      originalConfig.url === "/User/refreshtoken"
    ) {
      // If the refreshToken is invalid, redirect to Login
      store.dispatch("RESET_STORE");
      if (router.history.current.path !== "/login") {
        router.push("/login").catch(() => {});
      }
    } else {
      // Throw error for requests that don't match either conditions
      throw err;
    }
  });

  return instance;
};
export default fetchClient();
