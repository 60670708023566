import { redirectPageName } from "../utils/helpers";

export default function admin({ next, store }) {
  if (store.getters["auth/auth"].roleName !== "Admin") {
    return next({
      name: redirectPageName(),
    });
  }

  return next();
}
