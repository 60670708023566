import AuthService from "../../services/AuthService";

export default {
  namespaced: true,
  state: {
    token: window.localStorage.getItem("token") || "",
    refreshToken: window.localStorage.getItem("refreshToken") || "",
    userName: window.localStorage.getItem("userName") || "",
    roleName: window.localStorage.getItem("roleName") || "",
    loggedIn: [undefined, null, ""].includes(
      window.localStorage.getItem("token")
    )
      ? false
      : true,
    user: JSON.parse(window.localStorage.getItem("User")) || {},
  },
  actions: {
    authRequest: ({ commit }, user) => {
      return new Promise((resolve, reject) => {
        AuthService.login(user)
          .then((resp) => {
            commit("authSuccess", resp.data);
            resolve(resp);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
  getters: {
    auth(state) {
      return state;
    },
    loggedIn(state) {
      return !!state.token;
    },
  },
  mutations: {
    authSuccess: (state, res) => {
      if (res.isSuccess) {
        const { data } = res;
        state.token = data.token;
        state.userName = data.userName;
        state.roleName = data.roleName;
        state.refreshToken = data.refreshToken;
        state.user = data;
        for (const key in data) {
          if (Object.hasOwnProperty.call(data, key)) {
            window.localStorage.setItem(key, data[key]);
          }
        }
        window.localStorage.setItem("User", JSON.stringify(data));
      }
    },
    resetAuth: (state) => {
      state.token = "";
      state.userName = "";
      state.roleName = "";
      state.user = {};
      state.refreshToken = "";
      state.loggedIn = false;
      window.localStorage.clear();
    },
  },
};
